import { createTheme, darken } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#00a4e2",  //#00a4e2, 4682B4
      light: "var(--bg)",
      dark: "#F3F3F3",
      contrastText: "#C1D0E0",
    },
    secondary: {
      main: "#E8EFE8",
      light: "#A7A7A7",
    },
  },
  typography: {
    fontFamily: `'Lexend', 'sans-serif'`,
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: "white",
          textTransform: "capitalize",
          // backgroundColor: "#00a4e2",
          "&:hover": {
            backgroundColor: darken("#00a4e2", 0.2), // Darkens the primary color on hover
          },
        },
        // Add other button styles if needed
      },
    },
  },
});
