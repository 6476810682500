import React from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Pagination,
  Paper,
  Select,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setActiveEmployeeTabIndex } from "../../store";
const noData = require('../../assets/empty-box.png') as any;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper sx={{ mt: 8 }}>
          <Typography>{children}</Typography>
        </Paper>
      )}
    </div>
  );
}

const ResponsiveTable = (props: any) => {
  const {
    selectedEmployeeId,
    setSelectedEmployeeId,
    data,
    currentPage,
    setCurrentPage,
    totalCount,
    // setTotalCount,
    defaultPageSize,
    setDefaultPageSize,
  } = props;
  // const theme = useTheme();
  // const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledTableRow = styled(TableRow)({
    padding: 0,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "var(--hover)", // Change the background color on hover
    },
    "&:active": {
      backgroundColor: "var(--primary)", // Change the background color on hover
    },
    cursor: "pointer",
  })

  const TableCells = styled(TableCell)({
    padding: "5px 16px",
  });

  const ViewDetailsLink = styled(Button)`
    text-align: left;
    padding: 0;
    align-items: flex-start;
    text-transform: capitalize;
  `;

  //   const theme = useTheme();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: adds a smooth scroll animation
    });
  };

  const handleClickRow = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
    scrollToTop();
  };

  const getInitials = (fullName: string): string => {
    const words = fullName.split(" ");

    if (words.length === 1) {
      return words[0].slice(0, 2);
    }

    return words
      .map((word) => word.charAt(0))
      .slice(0, 2)
      .join("");
  };

  const handlePaginationChange = (event: any, page: number) => {
    // Update the current page when pagination changes
    setCurrentPage(page);
  };

  return (
    <TableContainer>
      {data && data.length > 0 ? (
        <React.Fragment>
          <Table sx={{ bgcolor: "#fff" }}>
            <TableHead>
              <TableRow>
                <TableCell>Employee Name</TableCell>
                <TableCell>Employee ID</TableCell>
                {/* <TableCell>Status</TableCell> */}
                <TableCell>Designation</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={{ bgcolor: "#fff" }}>
              {data.map((employee: any) => (
                <StyledTableRow
                  key={employee.emp_code}
                  // onClick={() => handleClickRow(employee.emp_code)}
                  selected={selectedEmployeeId === employee.emp_code}
                  sx={{
                    backgroundColor:
                      selectedEmployeeId === employee.emp_code
                        ? "#E8EFE8"
                        : "inherit",
                  }}
                >
                  <TableCells>
                    <ListItem>
                      <ListItemAvatar>
                        {employee?.profile_picture_url &&
                        employee.profile_picture_url !== "" ? (
                          <Avatar src={employee.profile_picture_url}></Avatar>
                        ) : (
                          <Avatar sx={{ bgcolor: "orange", fontSize: "16px" }}>
                            {getInitials(
                              `${employee.first_name} ${employee.last_name}`
                            )}
                          </Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ textTransform: "capitalize" }}
                        primary={`${employee.first_name} ${employee.last_name}`}
                        secondary={employee.phone_number}
                      />
                    </ListItem>
                  </TableCells>
                  <TableCells>{employee.emp_code}</TableCells>

                  <TableCells>{employee.designation}</TableCells>

                  <TableCells>
                    <Box
                      color="primary"
                      aria-label="view"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title={employee.phone_number} placement="top">
                        <IconButton
                          color="primary"
                          aria-label="call"
                          sx={{
                            border: "1px solid #718875",
                            borderRadius: "50%",
                            padding: "5px",
                            mr: 1,
                          }}
                        >
                          <CallIcon />
                        </IconButton>
                      </Tooltip>
                      <ViewDetailsLink
                        color="primary"
                        onClick={() => handleClickRow(employee.emp_code)}
                      >
                        View
                      </ViewDetailsLink>
                    </Box>
                  </TableCells>
                </StyledTableRow>
              ))}
            </TableBody>

            {/* <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                // colSpan={8}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter> */}
          </Table>

          {totalCount && (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mr: 3,
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Rows per page
                </InputLabel>
                <Select
                  value={defaultPageSize}
                  onChange={(e) => {
                    setDefaultPageSize(Number(e.target.value));
                  }}
                  sx={{ ml: 2, height: "30px" }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={55}>50</MenuItem>
                </Select>
              </Box>
              <Pagination
                variant="outlined"
                color="primary"
                count={Math.ceil(totalCount / defaultPageSize)}
                page={currentPage}
                onChange={handlePaginationChange}
                sx={{ mt: 2, mb: 2 }}
              />
            </Grid>
          )}
        </React.Fragment>
      ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: "350px", alignItems: "center", opacity: "0.6" }}>
            <img
              src={noData}
              alt='no data'
              style={{
                height: "140px",
                width: "140px",
              }}
            />
            <Typography
              sx={{
                color: 'var(--primary)',
                fontWeight: 700,
                textAlign: 'center',
                fontSize: '18px',
                marginTop: "20px"
              }}>No Employees found</Typography>
          </Box>
      )}
    </TableContainer>
  );
};

const StyledTabs = styled(({ className, ...other }: any) => (
  <Tabs
    {...other}
    classes={{
      root: className,
      flexContainer: "flexContainer",
      indicator: "indicator",
    }}
  ></Tabs>
))(({ theme }: { theme: Theme }) => ({
  "& .MuiButtonBase-root": {
    // margin: '0 0px',
    textTransform: "capitalize",
  },
  "& .MuiTabs-indicator": {
    height: "3px",
    borderRadius: "6px 6px",
  },
}));

const EmployeeList = (props: any) => {
  const {
    finalSearchValue,
    setFinalSearchValue,
    setAddNewEmployee,
    setCurrentPage,
    isSearch,
    setIsSearch,
    searchText,
    setSearchText,
  } = props;

  const dispatch = useDispatch();
  const activeEmployeeTabIndex = useSelector(
    (state: RootState) => state.activeEmployeeTabIndex
  );

  const tabList = ["All Employees", "Estimators", "Handyman"];

  const LoginButton = styled(Button)`
    text-align: left;
    color: var(--btn-text);
    background: var(--primary);
    font: Kufam;
    font-weight: 600;
    border-radius: 30px;
    padding: 10px 20px;
    &:hover {
      background: var(--hover); /* Change the background color on hover */
    }

    &:active {
      background: var(--primary); /* Change the background color on click */
    }
  `

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setActiveEmployeeTabIndex(newValue));
    setCurrentPage(1);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (searchText.length > 3) {
        setFinalSearchValue(searchText);
        setCurrentPage(1);
        setIsSearch(true);
      } else {
        toast.warning(`Search value should be more than 3 characters`, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };

  const handlePaste = (event: any) => {
    setIsSearch(true);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <StyledTabs
          value={activeEmployeeTabIndex ? activeEmployeeTabIndex : 0}
          onChange={handleChange}
        >
          {tabList.map((tab) => {
            return <Tab label={tab} />;
          })}
        </StyledTabs>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TextField
            sx={{
              mt: 2,
              padding: "0px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            placeholder="Search employees"
            variant="outlined"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              if (e.target.value === "") {
                setIsSearch(false);
                setFinalSearchValue("");
              }
            }}
            onPaste={handlePaste}
            onKeyDown={handleKeyPress}
            InputProps={{
              endAdornment: (
                <>
                  {searchText && (
                    <IconButton
                      onClick={() => {
                        setSearchText("");
                        setIsSearch(false);
                        setFinalSearchValue("");
                      }}
                      edge="end"
                      sx={{ mr: 0.2 }}
                    >
                      <CloseIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      if (searchText === "") {
                        toast.warning(`Search value is empty`, {
                          position: "top-right",
                          autoClose: 3000,
                        });
                      } else {
                        setCurrentPage(1);
                        setIsSearch(true);
                        setFinalSearchValue(searchText);
                      }
                    }}
                    edge="end"
                    sx={{ backgroundColor: "#EEFEF1", mr: 0.2 }}
                  >
                    <SearchIcon />
                  </IconButton>
                </>
              ),
              style: {
                background: "white",
                border: "none",
                borderRadius: "30px",
                padding: "0 10px",
                width: "250px",
                marginBottom: 20,
              },
            }}
          />
          <LoginButton
            variant="contained"
            sx={{ borderRadius: "30px" }}
            onClick={() => {
              setAddNewEmployee(true);
            }}
          >
            + Add new employee
          </LoginButton>
        </Box>
      </Box>
      {isSearch && finalSearchValue && finalSearchValue.length > 3 && (
        <Box>
          Search result for : <b>{finalSearchValue}</b>
        </Box>
      )}
      <TabPanel value={activeEmployeeTabIndex} index={activeEmployeeTabIndex}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <ResponsiveTable {...props} />
          </Grid>
        </Grid>
      </TabPanel>
    </Box>
  );
};

export default EmployeeList;
