import { Box, Button, Fade, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { postFeeback } from "../../action/timeline";
import {
  errorNotification,
  successNotification,
} from "../../components/shared/Toast/toast";
import { FeedbackType } from "../../types";
import StarIcon from "@mui/icons-material/Star";

type Props = {
  feedback: boolean;
  setFeedBack: (feedback: boolean) => void;
  style: object;
  selectedService: string;
  feedbackDetails: FeedbackType | null;
  role: string;
  feedbackDataApi: Function;
};

export default function FeedbackModal({
  feedback,
  setFeedBack,
  style,
  selectedService,
  feedbackDetails,
  role,
  feedbackDataApi,
}: Props) {
  const [value, setValue] = useState<number>(0);
  const [details, setDetails] = useState<string>("");
  const stars = [1, 2, 3, 4, 5];
  const disabledCondition = feedbackDetails?.Success || role === "vendor";

  useEffect(() => {
    if (disabledCondition && feedbackDetails?.Success) {
      setValue(feedbackDetails?.Feedback.rating);
    } else setValue(0);
  }, [disabledCondition, feedbackDetails, feedback]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={feedback}
      onClose={() => setFeedBack(false)}
      closeAfterTransition
      style={{ overflow: "auto" }}
    >
      <Fade in={feedback}>
        <Box
          sx={style}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: 32, fontWeight: 600 }}
          >
            Feedback
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: 400, mt: 2 }}>
            Rating
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {stars.length > 0 &&
              stars.map((starNum) => (
                <button
                  type="button"
                  key={starNum}
                  style={{
                    marginLeft: "10px",
                    border: "none",
                    background: "none",
                    cursor: disabledCondition ? "" : "pointer",
                  }}
                  onClick={() => setValue(starNum)}
                  disabled={disabledCondition}
                >
                  <StarIcon
                    style={{
                      fontSize: "60px",
                      color:
                        value === starNum || value > starNum
                          ? "#FFD014"
                          : "grey",
                    }}
                  />
                </button>
              ))}
          </div>
          <div
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontSize: "16px",
            }}
          >
            You rated {value || 0} out of 5
          </div>

          <Box
            sx={{
              gridRow: "1",
              gridColumn: "span 5",
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            {!disabledCondition ? (
              <Typography sx={{ fontSize: 14 }}>
                Please type in your comments (optional)
              </Typography>
            ) : (
              <Typography sx={{ fontSize: 14 }}>Comments</Typography>
            )}
            <textarea
              style={{
                border: "1px solid var(--primary)",
                borderRadius: 2,
                height: 100,
                padding: 4,
                resize: "none",
              }}
              onChange={(e) => {
                setDetails(e.target.value);
              }}
              disabled={disabledCondition}
              maxLength={5000}
              placeholder="Description must be 5000 characters or less."
            >
              {disabledCondition ? feedbackDetails?.Feedback.feedback : details}
            </textarea>
            <div style={{ display: "-webkit-flex" }}>
              <Typography sx={{ fontWeight: 500 }}>
                {details && `${5000 - details.length} characters remaining`}
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              float: "right",
              columnGap: 1,
              mt: 2,
              mb: 4,
              marginLeft: "auto",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setFeedBack(false);
                setValue(0);
                setDetails("");
              }}
              sx={{ cursor: "pointer" }}
            >
              Close
            </Button>
            {value > 0 && !disabledCondition && (
              <Button
                variant="contained"
                onClick={() => {
                  let body = {
                    rating: value,
                    feedback: details,
                    submitted: true,
                  };
                  setFeedBack(false);
                  setValue(0);
                  setDetails("");
                  postFeeback(selectedService, body)
                    .then(() => {
                      successNotification(
                        "Successfully submitted the feedback"
                      );
                      feedbackDataApi(selectedService);
                    })

                    .catch(() => {
                      errorNotification(
                        "Error in submitting the feedback.Please try again"
                      );
                    });
                }}
              >
                Save
              </Button>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
