import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import {
  errorNotification,
  successNotification,
} from "../../components/shared/Toast/toast";
import {
  BookingDetailTimelineResponse,
  selectedSlotsColor,
  timeSlot,
} from "../../types";
import { StepperType } from "../../types/StepperType";
import dayjs from "dayjs";
import {
  confirmWorkSlot,
  deleteEmployee,
  saveWorkSlot,
} from "../../action/timeline";
import CloseIcon from '@mui/icons-material/HighlightOff';

type Props = {
  bookingDetails: BookingDetailTimelineResponse;
  role: string | null;
  bgWorkColor: selectedSlotsColor;
  setAssignWorkSlot: (assign: timeSlot) => void;
  selectedService: string;
  timeLineData: Function;
  setAssignEmployeeModal: (assignEmployee: boolean) => void;
  setAssignWorkEmployee: (employee: boolean) => void;
  employeeWorkSlicedData: false | any[] | null;
  getInitials: (fullName: string) => string;
};

export default function SelectWorkSlotByVendor({
  bookingDetails,
  role,
  bgWorkColor,
  setAssignWorkSlot,
  selectedService,
  timeLineData,
  setAssignEmployeeModal,
  setAssignWorkEmployee,
  employeeWorkSlicedData,
  getInitials,
}: Props) {
  let selectWorkSlotByVendor: StepperType = {
    label: (
      <Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "var(--secondary)",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          {`${
            bookingDetails &&
            bookingDetails.in_progress.hasOwnProperty("selected_slot") &&
            bookingDetails.in_progress.selected_slot?.length > 0
              ? "Work Scheduled"
              : bookingDetails &&
                bookingDetails.scheduled.hasOwnProperty("slots") &&
                bookingDetails?.scheduled.slots?.length > 0
              ? bookingDetails?.scheduled.label
              : ""
          }`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "12px", fontWeight: 400, color: "#1F261F" }}
          >
            {bookingDetails &&
              Object.keys(bookingDetails?.scheduled)?.length > 0 &&
              bookingDetails?.scheduled.slots?.length > 0 &&
              dayjs(bookingDetails?.scheduled.created_at).format("DD MMM YYYY")}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "#A7A7A7", fontWeight: 400 }}
          >
            {bookingDetails &&
              Object.keys(bookingDetails?.scheduled)?.length > 0 &&
              bookingDetails?.scheduled.slots?.length > 0 &&
              dayjs(bookingDetails?.scheduled.created_at).format("hh:mm A")}
          </Typography>
        </Box>
      </Box>
    ),
    roles: ["vendor", "user"],
    description: (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "25px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              columnGap: "10px",
              rowGap: "12px",
            }}
          >
            {Object.keys(bookingDetails?.in_progress).length > 1 &&
            bookingDetails?.in_progress.hasOwnProperty("selected_slot") ? (
              <Typography
                sx={{ fontSize: "14px", fontWeight: 400 }}
              >{`Selected Slot and Employee`}</Typography>
            ) : role === "user" ? (
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                The Selected Slots
              </Typography>
            ) : role === "vendor" ? (
              <Typography
                sx={{ fontSize: "14px", fontWeight: 400 }}
              >{`Select Slot and Employee`}</Typography>
            ) : (
              ""
            )}
            <Box
              sx={{
                display: "flex",
                columnGap: "20px",
                width: "100%",
                overflowX: "auto"
              }}
            >
              {bookingDetails &&
                Object.keys(bookingDetails.scheduled).length > 0 &&
                bookingDetails.scheduled.hasOwnProperty("slots") &&
                bookingDetails.scheduled.slots.map((item: any) => {
                  const bgItem = bgWorkColor.find((bg) => bg.id === item.id);
                  const itemBackgroundColor =
                    bookingDetails?.scheduled.slots &&
                    role === "user" &&
                    bgItem &&
                    Object.keys(bookingDetails.scheduled).length > 0 &&
                    bookingDetails.scheduled.schedule_confirmed
                      ? bgItem.color
                      : role === "vendor" && bgItem
                      ? bgItem.color
                      : "white";
                  const itemBorder =
                    bookingDetails?.scheduled.slots &&
                      role === "user" &&
                      bgItem &&
                      Object.keys(bookingDetails.scheduled).length > 0 &&
                      bookingDetails.scheduled.schedule_confirmed
                      ? bgItem.color
                      : role === "vendor" && bgItem
                        ? "2px solid #007CC1"
                        : "2px solid #C1D0E0";
                  return (
                    <Box
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "8px",
                        alignItems: "center",
                        border: itemBorder,
                        borderRadius: "18px",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        minWidth: {xs: "200px", sm: "200px", md: "0"},
                        cursor:
                          bookingDetails?.scheduled.hasOwnProperty("slots") &&
                          role === "vendor"
                            ? "pointer"
                            : "",
                        backgroundColor: itemBackgroundColor,
                      }}
                      onClick={() => {
                        let body = {
                          time_slot: item.id,
                        };
                        if (
                          bookingDetails &&
                          role === "vendor" &&
                          !bookingDetails.in_progress.hasOwnProperty(
                            "selected_slot"
                          )
                        ) {
                          setAssignWorkSlot(item);
                          saveWorkSlot(selectedService, body)
                            .then((res) => {
                              timeLineData(selectedService);
                            })
                            .catch((err) => {
                              errorNotification(
                                "Error in saving the slot .Please try again"
                              );
                            });
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        {dayjs(item.date).format("dddd")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#A7A7A7",
                          fontWeight: 400,
                        }}
                      >
                        {dayjs(item.date).format("DD MMM YYYY")}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              columnGap: "10px",
              rowGap: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: "10px",
              }}
            >
              {Object.keys(bookingDetails.scheduled).length > 0 &&
                bookingDetails?.scheduled.hasOwnProperty("assigned_employee") &&
                bookingDetails?.scheduled.assigned_employee.length > 0 &&
                role === "vendor" && (
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    Employees assigned
                  </Typography>
                )}

              {role === "vendor" &&
                bookingDetails &&
                bookingDetails?.in_progress.in_progress === false &&
                bookingDetails?.scheduled.hasOwnProperty("assigned_employee") &&
                bookingDetails?.scheduled.assigned_employee?.length === 0 &&
                !bookingDetails.complete.complete && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      float: "right",
                      color: "var(--secondary)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAssignEmployeeModal(true);
                      setAssignWorkEmployee(true);
                    }}
                  >
                    +Assign Employee
                  </Typography>
                )}
            </Box>
            {Object.keys(bookingDetails.scheduled).length > 0 &&
              bookingDetails.scheduled.schedule_confirmed &&
              bookingDetails?.scheduled.hasOwnProperty("assigned_employee") &&
              bookingDetails?.scheduled.assigned_employee.length > 0 &&
              role === "user" && (
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Employees assigned
                </Typography>
              )}
            <div
              style={{
                display: "flex",
                columnGap: 30,
              }}
            >
              {Object.keys(bookingDetails.scheduled).length > 0 &&
              bookingDetails.scheduled.schedule_confirmed &&
              bookingDetails?.scheduled.hasOwnProperty("assigned_employee") &&
              role === "user"
                ? employeeWorkSlicedData &&
                  employeeWorkSlicedData.map((item) => {
                    return (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {item.profile_picture_url ? (
                          <Avatar
                            sx={{
                              width: 50,
                              height: 50,
                              marginBottom: 1,
                            }}
                            src={item.profile_picture_url}
                          >
                          </Avatar>
                        ) : (
                          <Avatar>
                            {getInitials(`${item.first_name} ${item.last_name}`)}
                          </Avatar>
                        )}
                        <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                          {item?.first_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#A7A7A7",
                            fontWeight: 400,
                          }}
                        >
                          {item?.emp_code} | {item?.designation}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#A7A7A7",
                            fontWeight: 400,
                          }}
                        >
                          {item?.phone_number}
                        </Typography>
                      </Box>
                    );
                  })
                : role === "vendor"
                ? bookingDetails?.scheduled.hasOwnProperty(
                    "assigned_employee"
                  ) &&
                  bookingDetails?.scheduled.assigned_employee?.length > 0 &&
                  employeeWorkSlicedData &&
                  employeeWorkSlicedData.map((item) => {
                    return (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {bookingDetails.scheduled.schedule_confirmed ===
                          false && (
                          <IconButton
                            aria-label="Delete"
                            size="small"
                            sx={{
                              textAlign: "start",
                              // width: 10,
                              marginLeft: "120px",
                              marginTop: "8px",
                              color: "#ff0000",
                              justifyContent: "end",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              let id =
                                bookingDetails?.scheduled?.assigned_employee.map(
                                  (item) => item.id
                                );
                              deleteEmployee(selectedService, id[0])
                                .then((res) => {
                                  successNotification(
                                    "Successfully deleted the employee"
                                  );
                                  timeLineData(selectedService);
                                })
                                .catch((err) => {
                                  errorNotification(err.response.data.detail);
                                });
                            }}
                          >
                            <CloseIcon style={{ width: "20px" }} />
                          </IconButton>
                        )}
                        <Avatar>
                          {getInitials(`${item.first_name} ${item.last_name}`)}
                        </Avatar>
                        <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                          {item?.first_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#A7A7A7",
                            fontWeight: 400,
                          }}
                        >
                          {item?.emp_code} | {item?.designation}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#A7A7A7",
                            fontWeight: 400,
                          }}
                        >
                          {item?.phone_number}
                        </Typography>
                      </Box>
                    );
                  })
                : ""}
            </div>

            {role === "vendor" &&
              bookingDetails &&
              employeeWorkSlicedData &&
              employeeWorkSlicedData.length > 0 &&
              !bookingDetails.in_progress.in_progress &&
              !bookingDetails.complete.complete &&
              !bookingDetails.scheduled.schedule_confirmed && (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 9999,
                    fontSize: 14,
                    height: "45px",
                    width: 200,
                    marginLeft: "auto",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    confirmWorkSlot(selectedService)
                      .then(() => {
                        successNotification("Work Slots are scheduled.");
                        timeLineData(selectedService);
                      })
                      .catch((err) => {
                        errorNotification(err.response.data.message);
                      });
                  }}
                >
                  Confirm Work Slot
                </Button>
              )}
          </Box>
        </Box>
      </Box>
    ),
  };
  return selectWorkSlotByVendor;
}
